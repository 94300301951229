/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    blockquote: "blockquote",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://anonymousmovement.blogspot.com/"
  }, React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2011/02/logo.png?w=300",
    alt: "Bienvenido a Anonymous Movement"
  }))), "\n", React.createElement(_components.p, null, "El sábado, 19 de febrero, el blog Anonymous Movement, lanzó un comunicado en nombre de Anonymous en el que se hablaba sobre la Operació València. Antes que nada me gustaría explicar que es Anonymous. Para esto citaré la explicación de este mismo blog."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Anonymous es un fenómeno social surgido del foro ", React.createElement(_components.a, {
    href: "http://www.4chan.org/"
  }, "4chan"), " a principios del año 2004 que se ha especializado en atacar a quienes cohiben la libertad. No tiene líderes, rechaza la violencia y su organización está completamente descentralizada, pues se lleva a cabo en infinidad de chats, foros, blogs y redes sociales."), "\n"), "\n", React.createElement(_components.p, null, "Viendo esta explicación queda claro que Anonymous no es un colectivo ni una organización. Anonymous es solo un nombre, una forma de unirse la gente que tiene un objetivo común, activistas anónimos se les puede llamar."), "\n", React.createElement(_components.p, null, "Para realizar las operaciones de Anonymous se forman grupos con un objetivo común coordinados mediante herramientas de Internet, por ejemplo blogs, microblogs como Twitter o foros como 4chan. La propia naturaleza de Anonymous lo convierte en una ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/Plurarqu%C3%ADa",
    title: "Plurarquía"
  }, "Plurarquía"), " de facto, donde nadie puede hablar en nombre de Anonymous."), "\n", React.createElement(_components.p, null, "Por este motivo no le veo ningún sentido que un blog dedicado a Anonymous escriba comunicados en su nombre como si fuera su líder y declare por ejemplo:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Los reponsables de estos ataques -que han reconocido ser unicamente ", React.createElement(_components.strong, null, "4 personas"), "- han actuado a título personal y no tienen nada que ver con Anonymous -pese a que hayan utilizado  el nombre de todos-, pues nuestros ataques siempre son ", React.createElement(_components.em, null, "pensados"), " entre todos y difundidos mediante el Twitter @anonops, blogs Anonymous, el IRC anonops.ru y Radio Doble R.", React.createElement(_components.a, {
    href: "http://anonymousmovement.blogspot.com/"
  })), "\n"), "\n", React.createElement(_components.p, null, "De esta declaración me sorprenden muchas cosas, la primera es que el escritor, “líder” de Anonymous, afirma saber que los responsables de esta operación eran 4 personas, cosa que no tiene forma de saber, en todo caso sabemos que como mínimos 4 personas reconocieron haber participado, 4 Anonymous no anónimos."), "\n", React.createElement(_components.p, null, "El resto de sorpresas me las llevo cuando leo, ", React.createElement(_components.em, null, "nuestros ataques"), ",  se referirá a los ataques de la gente que participa en ese blog supongo y que se cree “líder” de Anonymous. Con ", React.createElement(_components.em, null, "todos"), " supongo que también se referirá a todos los que participan en el blog Anonymous Movement, gente que se cree Anonymous pero en realidad no lo son, siguen teniendo una mentalidad autoritaria que no entra en ninguna parte de la filosofía Anonymous."), "\n", React.createElement(_components.p, null, "Este blog puede realizar una gran labor informativa y servir de referencia a muchos Anonymous de habla hispana, pero nunca puede erigirse en líder por derecho propio, tomar decisiones o lanzar comunicados, dando así muestra de su incoherencia de ideas."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
